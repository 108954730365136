import { graphql, useStaticQuery } from 'gatsby';

import { rejectNil } from '../../utils';
import { ButtonData, CtaSectionData, DataType, ImageData } from '../model';
import { DataHook, DataIndex } from '../types';
import { makeDataIndexFromList } from '../utils';
import { useButtonData } from './use-button-data';
import { useImageData } from './use-image-data';

const makeIndex = (
  graphqlResult: any,
  imageDataIndex: DataIndex<ImageData>,
  buttonDataIndex: DataIndex<ButtonData>,
): DataIndex<CtaSectionData> => {
  const list: CtaSectionData[] = graphqlResult.allContentfulCtaSection.nodes.map(
    (node): CtaSectionData => ({
      id: node.contentful_id,
      type: DataType.CtaSection,
      data: rejectNil({
        title: node.title,
        subtitle: node.subtitle,
        description: node.description,
        backgroundImage:
          imageDataIndex?.record?.[node.backgroundImage?.contentful_id],
        button: buttonDataIndex?.record?.[node.button?.contentful_id],
      }),
    }),
  );

  return makeDataIndexFromList(list);
};

export const useCtaSectionData: DataHook<CtaSectionData> = (
  id?: string,
): any => {
  const graphqlResult = useStaticQuery(graphql`
    query {
      allContentfulCtaSection {
        nodes {
          contentful_id
          title
          subtitle
          # description
          backgroundImage {
            contentful_id
          }
          button {
            contentful_id
          }
        }
      }
    }
  `);

  const index = makeIndex(graphqlResult, useImageData(), useButtonData());

  return id === undefined ? index : index.record[id];
};
