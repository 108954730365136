import { graphql, useStaticQuery } from 'gatsby';

import { rejectNil } from '../../utils';
import {
  ButtonData,
  DataType,
  ImageData,
  InterleavingSectionData,
} from '../model';
import { DataHook, DataIndex } from '../types';
import { makeDataIndexFromList } from '../utils';
import { useButtonData } from './use-button-data';
import { useImageData } from './use-image-data';

const makeIndex = (
  graphqlResult: any,
  imageDataIndex: DataIndex<ImageData>,
  buttonDataIndex: DataIndex<ButtonData>,
): DataIndex<InterleavingSectionData> => {
  const list: InterleavingSectionData[] = graphqlResult.allContentfulInterleavingSection.nodes.map(
    (node): InterleavingSectionData => ({
      id: node.contentful_id,
      type: DataType.InterleavingSection,
      data: rejectNil({
        mainTitle: node.mainTitle,
        title: node.title,
        subtitle: node.subtitle,
        hashName: node.hashName,
        description: node.description,
        image: imageDataIndex?.record?.[node?.image?.contentful_id],
        button: buttonDataIndex?.record?.[node?.button?.contentful_id],
        layout: node.layout,
        listItems: node?.listItems,
        showSign: node?.showSign,
        showQuote: node?.showQuote,
      }),
    }),
  );

  return makeDataIndexFromList(list);
};

export const useInterleavingSectionData: DataHook<InterleavingSectionData> = (
  id?: string,
): any => {
  const graphqlResult = useStaticQuery(graphql`
    query {
      allContentfulInterleavingSection {
        nodes {
          contentful_id
          title
          mainTitle
          subtitle
          hashName
          description {
            raw
          }
          image {
            contentful_id
          }
          button {
            contentful_id
          }
          layout
          listItems {
            title
            description
          }
          showSign
          showQuote
        }
      }
    }
  `);

  const index = makeIndex(graphqlResult, useImageData(), useButtonData());

  return id === undefined ? index : index.record[id];
};
