import { graphql, useStaticQuery } from 'gatsby';

import { rejectNil } from '../../utils';
import { DataType, SimpleSectionData } from '../model';
import { DataHook, DataIndex } from '../types';
import { makeDataIndexFromList } from '../utils';

const makeIndex = (graphqlResult: any): DataIndex<SimpleSectionData> => {
  const list: SimpleSectionData[] = graphqlResult.allContentfulSimpleSection.nodes.map(
    (node): SimpleSectionData => ({
      id: node.contentful_id,
      type: DataType.SimpleSection,
      data: rejectNil({
        title: node.title,
        content: node.content,
      }),
    }),
  );

  return makeDataIndexFromList(list);
};

export const useSimpleSectionData: DataHook<SimpleSectionData> = (
  id?: string,
): any => {
  const graphqlResult = useStaticQuery(graphql`
    query {
      allContentfulSimpleSection {
        nodes {
          contentful_id
          title
          content {
            raw
          }
        }
      }
    }
  `);

  const index = makeIndex(graphqlResult);

  return id === undefined ? index : index.record[id];
};
