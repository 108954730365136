import { graphql, useStaticQuery } from 'gatsby';

import { rejectNil } from '../../utils';
import { DataType, PageData, PageSectionsData, PageSeoData } from '../model';
import {
  CombinedIndex,
  DataHook,
  DataIndex,
  RequiredDataHooks,
} from '../types';
import { makeDataIndexFromList } from '../utils';
import { useBlogSectionData } from './use-blog-section-data';
import { useCarouselSectionData } from './use-carousel-section-data';
import { useCirclesSectionData } from './use-circles-section-data';
import { useContactSectionData } from './use-contact-section-data';
import { useCtaSectionData } from './use-cta-section-data';
import { useHeroSectionData } from './use-hero-section-data';
import { useInterleavingSectionData } from './use-interleaving-section';
import { useNewsletterSectionData } from './use-newsletter-section-data';
import { useNumbersSectionData } from './use-numbers-section-data';
import { usePageSeoData } from './use-page-seo-data';
import { usePartnersSectionData } from './use-partners-section-data';
import { useQuotationSectionData } from './use-quotation-section-data';
import { useSimpleSectionData } from './use-simple-section-data';
import { useTestimonialsSectionData } from './use-testimonials-section-data';
import { useTilesSectionData } from './use-tiles-section-data';
import { useTreatmentsDictionarySectionData } from './use-treatments-dictionary-section-data';
import { useVoucherSectionData } from './use-voucher-section-data';

const requiredDataHooks: RequiredDataHooks<PageSectionsData> = {
  [DataType.HeroSection]: useHeroSectionData,
  [DataType.InterleavingSection]: useInterleavingSectionData,
  [DataType.TreatmentsDictionarySection]: useTreatmentsDictionarySectionData,
  [DataType.ContactSection]: useContactSectionData,
  [DataType.CtaSection]: useCtaSectionData,
  [DataType.CarouselSection]: useCarouselSectionData,
  [DataType.NewsletterSection]: useNewsletterSectionData,
  [DataType.NumbersSection]: useNumbersSectionData,
  [DataType.TestimonialsSection]: useTestimonialsSectionData,
  [DataType.TilesSection]: useTilesSectionData,
  [DataType.PartnersSection]: usePartnersSectionData,
  [DataType.SimpleSection]: useSimpleSectionData,
  [DataType.CirclesSection]: useCirclesSectionData,
  [DataType.QuotationSection]: useQuotationSectionData,
  [DataType.VoucherSection]: useVoucherSectionData,
  [DataType.BlogSection]: useBlogSectionData,
};

const makeIndex = (
  graphqlResult: any,
  pageSeoDataIndex: DataIndex<PageSeoData>,
  sectionsDataIndex: CombinedIndex<PageSectionsData>,
): DataIndex<PageData> => {
  const list: PageData[] = graphqlResult.allContentfulPage.nodes.map(
    (node): PageData => ({
      id: node.contentful_id,
      type: DataType.Page,
      data: rejectNil({
        seo: pageSeoDataIndex.record[node.seo.contentful_id],
        pageId: node.pageId.pageId,
        slug: node.slug,
        sections:
          node.sections?.map(
            section => sectionsDataIndex[section.contentful_id],
          ) ?? [],
      }),
    }),
  );

  return makeDataIndexFromList(list);
};

export const usePageData: DataHook<PageData> = (id?: string): any => {
  const graphqlResult = useStaticQuery(graphql`
    query {
      allContentfulPage {
        nodes {
          contentful_id
          pageId {
            pageId
          }
          slug
          seo {
            contentful_id
          }
          sections {
            ... on ContentfulHeroSection {
              contentful_id
            }
            ... on ContentfulInterleavingSection {
              contentful_id
            }
            ... on ContentfulTreatmentsDictionarySection {
              contentful_id
            }
            ... on ContentfulContactSection {
              contentful_id
            }
            ... on ContentfulCtaSection {
              contentful_id
            }
            ... on ContentfulCarouselSection {
              contentful_id
            }
            ... on ContentfulNewsletterSection {
              contentful_id
            }
            ... on ContentfulNumbersSection {
              contentful_id
            }
            ... on ContentfulTestimonialsSection {
              contentful_id
            }
            ... on ContentfulTilesSection {
              contentful_id
            }
            ... on ContentfulPartnersSection {
              contentful_id
            }
            ... on ContentfulSimpleSection {
              contentful_id
            }
            ... on ContentfulCirclesSection {
              contentful_id
            }
            ... on ContentfulQuotationSection {
              contentful_id
            }
            ... on ContentfulVoucherSection {
              contentful_id
            }
            ... on ContentfulBlogSection {
              contentful_id
            }
          }
        }
      }
    }
  `);

  const sectionsDataIndex = Object.values(requiredDataHooks).reduce<
    CombinedIndex<PageSectionsData>
  >((acc, dataHook) => ({ ...acc, ...dataHook()?.record }), {});

  const index = makeIndex(graphqlResult, usePageSeoData(), sectionsDataIndex);

  return id === undefined ? index : index.record[id];
};
