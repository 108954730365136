import { BlogPostPartial, PageData, StaticPageId } from '../data';

export const resolveBlogPostPath = (
  pagesConfig: PageData[],
  blogPartial: BlogPostPartial,
): string => {
  // StaticPageId.KnowledgeBase is a blog by the definition of this website, optimize computation now each call invokes find operation
  const {
    data: { slug },
  } = pagesConfig.find(page => page.data.pageId === StaticPageId.KnowledgeBase);

  return `${slug}${blogPartial.slug}`;
};
