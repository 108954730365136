import './styles/row.scss';

import React, { CSSProperties, ReactElement } from 'react';

import { joinDefined } from '../../utils/array';
import { joinClassNames } from '../../utils/tsx';
import Col, { ColPrivateProps, ColProps } from './col';
import useScreens from './hooks/useScreens';
import {
  AlignValues,
  BreakpointProperty,
  JustifyValues,
  LayoutComponent,
} from './types';
import {
  makeClassName,
  makeClassNamesForBreakpoints,
  makeHiddenClassNameForBreakpoint,
  resolveBreakpointPropertyMap,
  resolveBreakpointPropertyValue,
} from './utils';

type ColElement = ReactElement<ColProps & ColPrivateProps, typeof Col>;

export interface RowProps {
  /** Vertical alignment of the row's children */
  align?: BreakpointProperty<AlignValues>;
  className?: string;
  children: ColElement | ColElement[];
  /** Justification style of the row children */
  justify?: BreakpointProperty<JustifyValues>;
  style?: BreakpointProperty<CSSProperties>;
  /** Whether the row should fill the height of it's container */
  fullHeight?: BreakpointProperty<boolean>;
  /**
   * Spaces between the elements. The first argument configures the horizontal
   * spaces. The second argument configures vertical spaces. Numerical values
   * will be converted to pixels.
   */
  gutters?: BreakpointProperty<
    [string | number, string | number] | string | number
  >;
  /** Whether the row should be hidden (equivalent of CSS "display: none") */
  hidden?: BreakpointProperty<boolean>;
}

const Row: React.FC<RowProps> = ({
  align,
  className,
  children,
  justify,
  style,
  fullHeight,
  gutters,
  hidden,
}) => {
  const [screens] = useScreens();

  const breakpoint = screens?.[0];

  const breakpointStyle = resolveBreakpointPropertyValue(breakpoint, style);
  const guttersBreakpointsMap = resolveBreakpointPropertyMap(gutters);
  const guttersValue = resolveBreakpointPropertyValue(
    breakpoint,
    guttersBreakpointsMap,
  );
  const resolvedGutters = (typeof guttersValue === 'number'
    ? [`${guttersValue}px`, `${guttersValue}px`]
    : typeof guttersValue === 'string'
    ? [guttersValue, guttersValue]
    : guttersValue?.map(val =>
        typeof val === 'number' ? `${val}px` : val,
      )) as [string, string] | undefined;

  const negativeMargin =
    resolvedGutters === undefined
      ? undefined
      : `calc(${resolvedGutters[1]} * -0.5)`;
  const positiveMargin =
    resolvedGutters === undefined
      ? undefined
      : `calc(${resolvedGutters[1]} * 0.5)`;

  return (
    <div
      className={joinClassNames(
        makeClassName(LayoutComponent.Row),
        ...makeClassNamesForBreakpoints(
          LayoutComponent.Row,
          'justify',
          justify,
        ),
        ...makeClassNamesForBreakpoints(LayoutComponent.Row, 'align', align),
        ...makeClassNamesForBreakpoints(
          LayoutComponent.Row,
          'full-height',
          fullHeight,
        ),
        makeHiddenClassNameForBreakpoint(
          LayoutComponent.Row,
          hidden,
          breakpoint,
        ),
        className,
      )}
      style={{
        ...breakpointStyle,
        margin: joinDefined(' ', [
          negativeMargin,
          negativeMargin,
          positiveMargin,
        ]),
      }}
    >
      {[children]
        .flat()
        .map((column: ColElement, index: number) =>
          React.cloneElement(column, { gutters: resolvedGutters, key: index }),
        )}
    </div>
  );
};

export default Row;
