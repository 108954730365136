import { graphql, useStaticQuery } from 'gatsby';

import { rejectNil } from '../../utils';
import { DataType, ImageData, TreatmentsDictionarySectionData } from '../model';
import { DataHook, DataIndex } from '../types';
import { makeDataIndexFromList } from '../utils';
import { useImageData } from './use-image-data';

const makeIndex = (
  graphqlResult: any,
  imageDataIndex: DataIndex<ImageData>,
): DataIndex<TreatmentsDictionarySectionData> => {
  const list: TreatmentsDictionarySectionData[] = graphqlResult.allContentfulTreatmentsDictionarySection.nodes.map(
    (node): TreatmentsDictionarySectionData => ({
      id: node.contentful_id,
      type: DataType.TreatmentsDictionarySection,
      data: rejectNil({
        title: node.title,
        description: node.description,
        treatments: node.treatments.map(treatment => ({
          ...treatment,
          treatmentImage:
            imageDataIndex?.record?.[treatment?.treatmentImage?.contentful_id],
        })),
        mobileFaceMapImage:
          imageDataIndex?.record?.[node?.mobileFaceMapImage?.contentful_id],
        desktopFaceMapImage:
          imageDataIndex?.record?.[node?.desktopFaceMapImage?.contentful_id],
      }),
    }),
  );

  return makeDataIndexFromList(list);
};

export const useTreatmentsDictionarySectionData: DataHook<TreatmentsDictionarySectionData> = (
  id?: string,
): any => {
  const graphqlResult = useStaticQuery(graphql`
    query {
      allContentfulTreatmentsDictionarySection {
        nodes {
          contentful_id
          title
          description {
            raw
          }
          desktopFaceMapImage {
            contentful_id
          }
          mobileFaceMapImage {
            contentful_id
          }
          treatments {
            name
            description {
              raw
            }
            areas
            problems
            treatmentImage {
              contentful_id
            }
            treatmentGroups {
              title
              description
              pricesList {
                description
                priceDiscount
                priceFrom
                pricePrefix
                priceSuffix
                priceTo
                title
              }
              extra {
                raw
              }
            }
          }
        }
      }
    }
  `);

  const index = makeIndex(graphqlResult, useImageData());

  return id === undefined ? index : index.record[id];
};
