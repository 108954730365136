import { graphql, useStaticQuery } from 'gatsby';

import { rejectNil } from '../../utils';
import { DataType, EuropeanFundsSectionData, ImageData } from '../model';
import { DataHook, DataIndex } from '../types';
import { makeDataIndexFromList } from '../utils';
import { useImageData } from './use-image-data';

const makeIndex = (
  graphqlResult: any,
  imageDataIndex: DataIndex<ImageData>,
): DataIndex<EuropeanFundsSectionData> => {
  const list: EuropeanFundsSectionData[] = graphqlResult.allContentfulEuropeanFundsSection.nodes.map(
    (node): EuropeanFundsSectionData => ({
      id: node.contentful_id,
      type: DataType.EuropeanFundsSection,
      data: rejectNil({
        content: node.content,
        images:
          node.images.map(
            image => imageDataIndex?.record?.[image?.contentful_id],
          ) ?? [],
      }),
    }),
  );

  return makeDataIndexFromList(list);
};

export const useEuropeanFundsSectionData: DataHook<EuropeanFundsSectionData> = (
  id?: string,
): any => {
  const graphqlResult = useStaticQuery(graphql`
    query {
      allContentfulEuropeanFundsSection {
        nodes {
          contentful_id
          content {
            raw
          }
          images {
            contentful_id
          }
        }
      }
    }
  `);

  const index = makeIndex(graphqlResult, useImageData());

  return id === undefined ? index : index.record[id];
};
