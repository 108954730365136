import { graphql, useStaticQuery } from 'gatsby';

import { rejectNil } from '../../utils';
import { DataType, ImageData } from '../model';
import { DataHook, DataIndex } from '../types';
import { makeDataIndexFromList } from '../utils';

const makeIndex = (graphqlResult: any): DataIndex<ImageData> => {
  const list: ImageData[] = graphqlResult.allContentfulImage.nodes.map(
    (node): ImageData => ({
      id: node.contentful_id,
      type: DataType.Image,
      data: rejectNil({
        alt: node.alt,
        bitmap: {
          url: node.bitmap?.file?.url,
          fluid: node.bitmap?.fluid,
        },
        vector: {
          url: node?.vector?.file?.url,
        },
      }),
    }),
  );

  return makeDataIndexFromList(list);
};

export const useImageData: DataHook<ImageData> = (id?: string): any => {
  const graphqlResult = useStaticQuery(graphql`
    query {
      allContentfulImage {
        nodes {
          contentful_id
          alt
          vector {
            file {
              url
            }
          }
          bitmap {
            file {
              url
            }
            fluid: gatsbyImageData(
              layout: CONSTRAINED
              placeholder: TRACED_SVG
              quality: 100
            )
          }
        }
      }
    }
  `);

  const index = makeIndex(graphqlResult);

  return id === undefined ? index : index.record[id];
};
