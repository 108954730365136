import { graphql, useStaticQuery } from 'gatsby';

import { rejectNil } from '../../utils';
import { DataType, ImageData, NewsletterSectionData } from '../model';
import { DataHook, DataIndex } from '../types';
import { makeDataIndexFromList } from '../utils';
import { useImageData } from './use-image-data';

const makeIndex = (
  graphqlResult: any,
  imageDataIndex: DataIndex<ImageData>,
): DataIndex<NewsletterSectionData> => {
  const list: NewsletterSectionData[] = graphqlResult.allContentfulNewsletterSection.nodes.map(
    (node): NewsletterSectionData => ({
      id: node.contentful_id,
      type: DataType.NewsletterSection,
      data: rejectNil({
        title: node.title,
        subtitle: node.subtitle,
        extra: node.extra,
        newsletterActionUrl: node.newsletterActionUrl,
        backgroundImage:
          imageDataIndex?.record?.[node.backgroundImage?.contentful_id],
      }),
    }),
  );

  return makeDataIndexFromList(list);
};

export const useNewsletterSectionData: DataHook<NewsletterSectionData> = (
  id?: string,
): any => {
  const graphqlResult = useStaticQuery(graphql`
    query {
      allContentfulNewsletterSection {
        nodes {
          contentful_id
          title
          subtitle
          newsletterActionUrl
          backgroundImage {
            contentful_id
          }
          extra
        }
      }
    }
  `);

  const index = makeIndex(graphqlResult, useImageData());

  return id === undefined ? index : index.record[id];
};
