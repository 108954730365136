import { graphql, useStaticQuery } from 'gatsby';

import { rejectNil } from '../../utils';
import { ButtonData, DataType, VoucherSectionData } from '../model';
import { DataHook, DataIndex } from '../types';
import { makeDataIndexFromList } from '../utils';
import { useButtonData } from './use-button-data';

const makeIndex = (
  graphqlResult: any,
  buttonDataIndex: DataIndex<ButtonData>,
): DataIndex<VoucherSectionData> => {
  const list: VoucherSectionData[] = graphqlResult.allContentfulVoucherSection.nodes.map(
    (node): VoucherSectionData => ({
      id: node.contentful_id,
      type: DataType.VoucherSection,
      data: rejectNil({
        content: node.content,
        button: buttonDataIndex?.record?.[node?.button?.contentful_id],
      }),
    }),
  );

  return makeDataIndexFromList(list);
};

export const useVoucherSectionData: DataHook<VoucherSectionData> = (
  id?: string,
): any => {
  const graphqlResult = useStaticQuery(graphql`
    query {
      allContentfulVoucherSection {
        nodes {
          contentful_id
          content {
            raw
          }
          button {
            contentful_id
          }
        }
      }
    }
  `);

  const index = makeIndex(graphqlResult, useButtonData());

  return id === undefined ? index : index.record[id];
};
