import { graphql, useStaticQuery } from 'gatsby';

import { rejectNil } from '../../utils';
import { ButtonData, DataType } from '../model';
import { DataHook, DataIndex } from '../types';
import { makeDataIndexFromList } from '../utils';

const makeIndex = (graphqlResult: any): DataIndex<ButtonData> => {
  const list: ButtonData[] = graphqlResult.allContentfulButton.nodes.map(
    (node): ButtonData => ({
      id: node.contentful_id,
      type: DataType.Button,
      data: rejectNil({
        label: node.label,
        hashName: node.hashName,
        link: node.link?.pageId,
        url: node.url,
        type: node.type,
      }),
    }),
  );

  return makeDataIndexFromList(list);
};

export const useButtonData: DataHook<ButtonData> = (id?: string): any => {
  const graphqlResult = useStaticQuery(graphql`
    query {
      allContentfulButton {
        nodes {
          contentful_id
          label
          link {
            pageId
          }
          url
          type
          hashName
        }
      }
    }
  `);

  const index = makeIndex(graphqlResult);

  return id === undefined ? index : index.record[id];
};
