import { graphql, useStaticQuery } from 'gatsby';

import { rejectNil } from '../../utils';
import { CarouselSectionData, DataType, ImageData } from '../model';
import { DataHook, DataIndex } from '../types';
import { makeDataIndexFromList } from '../utils';
import { useImageData } from './use-image-data';

const makeIndex = (
  graphqlResult: any,
  imageDataIndex: DataIndex<ImageData>,
): DataIndex<CarouselSectionData> => {
  const list: CarouselSectionData[] = graphqlResult.allContentfulCarouselSection.nodes.map(
    (node): CarouselSectionData => ({
      id: node.contentful_id,
      type: DataType.CarouselSection,
      data: rejectNil({
        title: node?.title,
        subtitle: node?.subtitle,
        description: node?.description,
        items: node?.items?.map(item => ({
          ...item,
          image: imageDataIndex?.record?.[item?.image?.contentful_id],
        })),
      }),
    }),
  );

  return makeDataIndexFromList(list);
};

export const useCarouselSectionData: DataHook<CarouselSectionData> = (
  id?: string,
): any => {
  const graphqlResult = useStaticQuery(graphql`
    query {
      allContentfulCarouselSection {
        nodes {
          contentful_id
          title
          subtitle
          description {
            raw
          }
          items {
            title
            subtitle
            description
            image {
              contentful_id
            }
          }
        }
      }
    }
  `);

  const index = makeIndex(graphqlResult, useImageData());

  return id === undefined ? index : index.record[id];
};
