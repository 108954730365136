import { graphql, useStaticQuery } from 'gatsby';

import { rejectNil } from '../../utils';
import { ContactSectionData, DataType } from '../model';
import { DataHook, DataIndex } from '../types';
import { makeDataIndexFromList } from '../utils';

const makeIndex = (graphqlResult: any): DataIndex<ContactSectionData> => {
  const list: ContactSectionData[] = graphqlResult.allContentfulContactSection.nodes.map(
    (node): ContactSectionData => ({
      id: node.contentful_id,
      type: DataType.ContactSection,
      data: rejectNil({
        title: node.title,
        subtitle: node.subtitle,
      }),
    }),
  );

  return makeDataIndexFromList(list);
};

export const useContactSectionData: DataHook<ContactSectionData> = (
  id?: string,
): any => {
  const graphqlResult = useStaticQuery(graphql`
    query {
      allContentfulContactSection {
        nodes {
          contentful_id
          title
          subtitle
        }
      }
    }
  `);

  const index = makeIndex(graphqlResult);

  return id === undefined ? index : index.record[id];
};
