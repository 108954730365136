import { graphql, useStaticQuery } from 'gatsby';

import { rejectNil } from '../../utils';
import { DataType, NumbersSectionData } from '../model';
import { DataHook, DataIndex } from '../types';
import { makeDataIndexFromList } from '../utils';

const makeIndex = (graphqlResult: any): DataIndex<NumbersSectionData> => {
  const list: NumbersSectionData[] = graphqlResult.allContentfulNumbersSection.nodes.map(
    (node): NumbersSectionData => ({
      id: node.contentful_id,
      type: DataType.NumbersSection,
      data: rejectNil({
        title: node.title,
        subtitle: node.subtitle,
        description: node.description,
        items: node.items,
      }),
    }),
  );

  return makeDataIndexFromList(list);
};
export const useNumbersSectionData: DataHook<NumbersSectionData> = (
  id?: string,
): any => {
  const graphqlResult = useStaticQuery(graphql`
    query {
      allContentfulNumbersSection {
        nodes {
          contentful_id
          title
          items {
            title
            count
            unit
          }
        }
      }
    }
  `);

  const index = makeIndex(graphqlResult);

  return id === undefined ? index : index.record[id];
};
