//******************************************************************************
// Copyright 2021 BlackBerry. All Rights Reserved.

import { useLocation } from '@reach/router';

export const useQueryParams = (): URLSearchParams => {
  const location = useLocation();

  return new URLSearchParams(location.search);
};
