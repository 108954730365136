import { graphql, useStaticQuery } from 'gatsby';

import { rejectNil } from '../../utils';
import { ConfigData, DataType, ImageData } from '../model';
import { DataHook, DataIndex } from '../types';
import { makeDataIndexFromList } from '../utils';
import { useImageData } from './use-image-data';

const makeLinks = data =>
  (data ?? []).map(item => ({
    ...item,
    link: item.link.pageId,
  }));

const makeIndex = (
  graphqlResult: any,
  imageDataIndex: DataIndex<ImageData>,
): DataIndex<ConfigData> => {
  const list: ConfigData[] = graphqlResult.allContentfulConfig.nodes.map(
    (node): ConfigData => ({
      id: node.contentful_id,
      type: DataType.Config,
      data: rejectNil({
        companyName: node.companyName,
        currency: node.currency,
        email: node.email,
        address: node.address,
        logo: imageDataIndex.record?.[node.logo?.contentful_id],
        translations: node.translations,
        phoneNumber: node.phoneNumber,
        socialMedias: node.socialMedias,
        theme: node.theme,
        mainNavigation: makeLinks(node.mainNavigation),
        footerNavigation: makeLinks(node.footerNavigation),
      }),
    }),
  );

  return makeDataIndexFromList(list);
};

export const useConfigData: DataHook<ConfigData> = (): any => {
  const graphqlResult = useStaticQuery(graphql`
    query {
      allContentfulConfig {
        nodes {
          email
          phoneNumber
          currency
          companyName
          address {
            raw
          }
          logo {
            contentful_id
          }
          theme {
            fontFamilies
            primaryColor
            secondaryColor
            onSecondaryColor
            greyColor
            textColor
          }
          socialMedias {
            name
            url
          }
          mainNavigation {
            title
            link {
              pageId
            }
          }
          footerNavigation {
            title
            link {
              pageId
            }
          }
          translations {
            owner
            positionTitle
            positionSubtitle
            mediaTitle
          }
        }
      }
    }
  `);

  const index = makeIndex(graphqlResult, useImageData());

  return index;
};
