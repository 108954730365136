import { graphql, useStaticQuery } from 'gatsby';

import { rejectNil } from '../../utils';
import { DataType, ImageData, QuotationSectionData } from '../model';
import { DataHook, DataIndex } from '../types';
import { makeDataIndexFromList } from '../utils';
import { useImageData } from './use-image-data';

const makeIndex = (
  graphqlResult: any,
  imageDataIndex: DataIndex<ImageData>,
): DataIndex<QuotationSectionData> => {
  const list: QuotationSectionData[] = graphqlResult.allContentfulQuotationSection.nodes.map(
    (node): QuotationSectionData => ({
      id: node.contentful_id,
      type: DataType.QuotationSection,
      data: rejectNil({
        content: node.content,
        backgroundImage:
          imageDataIndex?.record?.[node?.backgroundImage?.contentful_id],
      }),
    }),
  );

  return makeDataIndexFromList(list);
};

export const useQuotationSectionData: DataHook<QuotationSectionData> = (
  id?: string,
): any => {
  const graphqlResult = useStaticQuery(graphql`
    query {
      allContentfulQuotationSection {
        nodes {
          contentful_id
          content {
            raw
          }
          backgroundImage {
            contentful_id
          }
        }
      }
    }
  `);

  const index = makeIndex(graphqlResult, useImageData());

  return id === undefined ? index : index.record[id];
};
