import './styles/column.scss';

import React, { CSSProperties } from 'react';

import { joinClassNames } from '../../utils/tsx';
import { useScreens } from '.';
import { BreakpointProperty, LayoutComponent } from './types';
import {
  makeClassName,
  makeClassNamesForBreakpoints,
  makeHiddenClassNameForBreakpoint,
  resolveBreakpointPropertyValue,
} from './utils';

export interface ColProps {
  className?: string;
  /** Number of columns to occupy */
  span?: BreakpointProperty<number>;
  /** Number of columns to offset column from the left */
  offset?: BreakpointProperty<number>;
  /** Order of the column */
  order?: BreakpointProperty<number>;
  style?: BreakpointProperty<CSSProperties>;
  /** Whether the column should fit the content width. Default: false */
  fitContentWidth?: BreakpointProperty<boolean>;
  /** Whether the column should be hidden (equivalent of CSS "display: none") */
  hidden?: BreakpointProperty<boolean>;
  center?: boolean;
}

export interface ColPrivateProps {
  gutters?: [string, string];
}

const Col: React.FC<ColProps> = ({
  children,
  className = '',
  span,
  offset,
  order,
  style,
  hidden,
  fitContentWidth,
  center = false,
  ...privateProps
}) => {
  const [screens] = useScreens();

  const breakpoint = screens?.[0];

  const breakpointStyle = resolveBreakpointPropertyValue(breakpoint, style);
  const { gutters = ['0px', '0px'] } = privateProps as ColPrivateProps;

  return (
    <div
      className={joinClassNames(
        makeClassName(LayoutComponent.Column),
        ...makeClassNamesForBreakpoints(LayoutComponent.Column, 'span', span),
        ...makeClassNamesForBreakpoints(LayoutComponent.Column, 'order', order),
        ...makeClassNamesForBreakpoints(
          LayoutComponent.Column,
          'fit-content-width',
          fitContentWidth,
        ),
        ...makeClassNamesForBreakpoints(
          LayoutComponent.Column,
          'offset',
          offset,
        ),
        makeHiddenClassNameForBreakpoint(
          LayoutComponent.Column,
          hidden,
          breakpoint,
        ),
        className,
      )}
      style={breakpointStyle}
    >
      <div
        className={makeClassName('content', LayoutComponent.Column)}
        style={{
          paddingBottom: `calc(${gutters[1]} * 0.5)`,
          paddingLeft: `calc(${gutters[0]} * 0.5)`,
          paddingRight: `calc(${gutters[0]} * 0.5)`,
          paddingTop: `calc(${gutters[1]} * 0.5)`,
          ...(center ? { display: 'flex', justifyContent: 'center' } : {}),
        }}
      >
        {children}
      </div>
    </div>
  );
};

export default Col;
