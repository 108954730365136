export enum DataType {
  Button = 'button',
  Config = 'config',
  Image = 'image',
  Page = 'page',
  BlogPostPage = 'blog-post-page',
  PageSeo = 'page-seo',
  HeroSection = 'hero-section',
  InterleavingSection = 'interleaving-section',
  TreatmentsDictionarySection = 'treatments-dictionary-section',
  ContactSection = 'contact-section',
  CtaSection = 'cta-section',
  CarouselSection = 'carousel-section',
  NewsletterSection = 'newsletter-section',
  NumbersSection = 'numbers-section',
  TestimonialsSection = 'testimonials-section',
  PartnersSection = 'partners-section',
  TilesSection = 'tiles-section',
  SimpleSection = 'simple-section',
  EuropeanFundsSection = 'european-funds-section',
  CirclesSection = 'circles-section',
  QuotationSection = 'quotation-section',
  VoucherSection = 'voucher-section',
  BlogSection = 'blog-section',
}

export type DataEntryBase<TType extends DataType, TData extends unknown> = {
  id: string;
  type: TType;
  data: TData;
};
