import { graphql, useStaticQuery } from 'gatsby';

import { rejectNil } from '../../utils';
import { DataType, HeroSectionData, ImageData } from '../model';
import { DataHook, DataIndex } from '../types';
import { makeDataIndexFromList } from '../utils';
import { useImageData } from './use-image-data';

const makeIndex = (
  graphqlResult: any,
  imageDataIndex: DataIndex<ImageData>,
): DataIndex<HeroSectionData> => {
  const list: HeroSectionData[] = graphqlResult.allContentfulHeroSection.nodes.map(
    (node): HeroSectionData => ({
      id: node.contentful_id,
      type: DataType.HeroSection,
      data: rejectNil({
        title: node.title,
        subtitle: node.subtitle,
        description: node.description,
        desktopImage: imageDataIndex?.record?.[node.desktopImage.contentful_id],
        mobileImage: imageDataIndex?.record?.[node.mobileImage?.contentful_id],
        layout: node.layout,
        showSign: node?.showSign,
      }),
    }),
  );

  return makeDataIndexFromList(list);
};

export const useHeroSectionData: DataHook<HeroSectionData> = (
  id?: string,
): any => {
  const graphqlResult = useStaticQuery(graphql`
    query {
      allContentfulHeroSection {
        nodes {
          contentful_id
          title {
            raw
          }
          subtitle
          description {
            raw
          }
          desktopImage {
            contentful_id
          }
          mobileImage {
            contentful_id
          }
          layout
          showSign
        }
      }
    }
  `);

  const index = makeIndex(graphqlResult, useImageData());

  return id === undefined ? index : index.record[id];
};
