import { graphql, useStaticQuery } from 'gatsby';

import { rejectNil } from '../../utils';
import { CirclesSectionData, DataType } from '../model';
import { DataHook, DataIndex } from '../types';
import { makeDataIndexFromList } from '../utils';
import { ButtonData } from './../model/button';
import { useButtonData } from './use-button-data';

const makeIndex = (
  graphqlResult: any,
  buttonDataIndex: DataIndex<ButtonData>,
): DataIndex<CirclesSectionData> => {
  const list: CirclesSectionData[] = graphqlResult.allContentfulCirclesSection.nodes.map(
    (node): CirclesSectionData => ({
      id: node.contentful_id,
      type: DataType.CirclesSection,
      data: rejectNil({
        title: node.title,
        button: buttonDataIndex?.record?.[node?.button?.contentful_id],
        items: node?.items?.map(item => ({
          ...item,
          button: buttonDataIndex?.record?.[item?.button?.contentful_id],
        })),
      }),
    }),
  );

  return makeDataIndexFromList(list);
};

export const useCirclesSectionData: DataHook<CirclesSectionData> = (
  id?: string,
): any => {
  const graphqlResult = useStaticQuery(graphql`
    query {
      allContentfulCirclesSection {
        nodes {
          contentful_id
          title
          button {
            contentful_id
          }
          items {
            title
            description
            button {
              contentful_id
            }
          }
        }
      }
    }
  `);

  const index = makeIndex(graphqlResult, useButtonData());

  return id === undefined ? index : index.record[id];
};
