import { ContentfulRichText } from '../../types/contentful';
import { DataEntryBase, DataType } from './common';
import { ImageData } from './image';

// uniq id for the page, should match 1:1 values from CMS Config model
export enum StaticPageId {
  Home = 'Strona Główna',
  About = 'Kim Jestem',
  HowWeWork = 'Jak Pracuję',
  Treatments = 'Zabiegi I Ceny',
  KnowledgeBase = 'Baza Wiedzy',
  Contact = 'Kontakt',
  PrivacyPolicy = 'Polityka Prywatności',
  Regulations = 'Regulamin',
}

// uniq template type for the page generation, should match 1:1 values from CMS Config model
export enum TemplateType {
  Page = 'page',
  BlogPost = 'blog-post',
}

export interface NavigationConfig {
  link: StaticPageId;
  title: string;
}

export interface SocialMediaConfig {
  name: string;
  url: string;
}

export interface ThemeConfig {
  fontFamilies: string[];
  primaryColor: string;
  secondaryColor: string;
  onSecondaryColor: string;
  greyColor: string;
  textColor: string;
}

export type ConfigData = DataEntryBase<
  DataType.Config,
  {
    companyName: string;
    phoneNumber: string;
    currency: string;
    email: string;
    address: ContentfulRichText;
    logo: ImageData;
    footerNavigation: NavigationConfig[];
    mainNavigation: NavigationConfig[];
    socialMedias: SocialMediaConfig[];
    theme: ThemeConfig;
    translations?: {
      mediaTitle?: string;
      owner?: string;
      positionSubtitle?: string;
      positionTitle?: string;
    };
  }
>;
